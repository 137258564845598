/* tslint:disable */
/* eslint-disable */
/* @relayHash 2819a50f1c97f57e2a10599c147ce632 */

import { ConcreteRequest } from "relay-runtime";
export type FEATURE_FLAG = "ALTERNATE_OLDEST_FIRST_VIEW" | "AVATARS" | "DEFAULT_QA_STORY_MODE" | "DISABLE_LIVE_UPDATES" | "DISABLE_WARN_USER_OF_TOXIC_COMMENT" | "DISCUSSIONS" | "ENABLE_AMP" | "ENABLE_QA" | "ENABLE_RATINGS_AND_REVIEWS" | "EXTERNAL_MEDIA" | "FLATTEN_REPLIES" | "NEW_COMMENT_COUNT" | "READ_MORE_NEW_TAB" | "REDUCED_SECURITY_MODE" | "RTE_SARCASM" | "SECTIONS" | "SITE_MODERATOR" | "VIEWER_COUNT" | "%future added value";
export type SearchStoryFetchQueryVariables = {
    query: string;
    limit: number;
    siteID?: string | null;
};
export type SearchStoryFetchQueryResponse = {
    readonly settings: {
        readonly multisite: boolean;
        readonly featureFlags: ReadonlyArray<FEATURE_FLAG>;
    };
    readonly stories: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly site: {
                    readonly name: string;
                    readonly id: string;
                };
                readonly metadata: {
                    readonly title: string | null;
                    readonly section: string | null;
                    readonly author: string | null;
                } | null;
            };
        }>;
        readonly pageInfo: {
            readonly hasNextPage: boolean;
        };
    };
};
export type SearchStoryFetchQuery = {
    readonly response: SearchStoryFetchQueryResponse;
    readonly variables: SearchStoryFetchQueryVariables;
};



/*
query SearchStoryFetchQuery(
  $query: String!
  $limit: Int!
  $siteID: ID
) {
  settings {
    multisite
    featureFlags
    id
  }
  stories(query: $query, first: $limit, siteID: $siteID) {
    edges {
      node {
        id
        site {
          name
          id
        }
        metadata {
          title
          section
          author
        }
      }
    }
    pageInfo {
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "limit"
    } as any), v1 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "query"
    } as any), v2 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "siteID"
    } as any), v3 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "multisite",
        "storageKey": null
    } as any), v4 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "featureFlags",
        "storageKey": null
    } as any), v5 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v6 = ({
        "alias": null,
        "args": [
            {
                "kind": "Variable",
                "name": "first",
                "variableName": "limit"
            },
            {
                "kind": "Variable",
                "name": "query",
                "variableName": "query"
            },
            {
                "kind": "Variable",
                "name": "siteID",
                "variableName": "siteID"
            }
        ],
        "concreteType": "StoriesConnection",
        "kind": "LinkedField",
        "name": "stories",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "StoryEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "Story",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                            (v5 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "Site",
                                "kind": "LinkedField",
                                "name": "site",
                                "plural": false,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "name",
                                        "storageKey": null
                                    },
                                    (v5 /*: any*/)
                                ],
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "StoryMetadata",
                                "kind": "LinkedField",
                                "name": "metadata",
                                "plural": false,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "title",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "section",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "author",
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any);
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "SearchStoryFetchQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Settings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                        (v3 /*: any*/),
                        (v4 /*: any*/)
                    ],
                    "storageKey": null
                },
                (v6 /*: any*/)
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v1 /*: any*/),
                (v0 /*: any*/),
                (v2 /*: any*/)
            ],
            "kind": "Operation",
            "name": "SearchStoryFetchQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Settings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        (v5 /*: any*/)
                    ],
                    "storageKey": null
                },
                (v6 /*: any*/)
            ]
        },
        "params": {
            "id": "2819a50f1c97f57e2a10599c147ce632",
            "metadata": {},
            "name": "SearchStoryFetchQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = '33de4e88b251811cf48a12e178d52191';
export default node;
