/* tslint:disable */
/* eslint-disable */
/* @relayHash f22aacccb0d47bdc5469c90ce3baa984 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type StoriesRouteQueryVariables = {
    searchFilter?: string | null;
};
export type StoriesRouteQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"StoryTableContainer_query">;
};
export type StoriesRouteQuery = {
    readonly response: StoriesRouteQueryResponse;
    readonly variables: StoriesRouteQueryVariables;
};



/*
query StoriesRouteQuery(
  $searchFilter: String
) {
  ...StoryTableContainer_query_3Tn0NJ
}

fragment SiteFilterContainer_query on Query {
  sites(first: 10) {
    edges {
      node {
        id
        ...SiteFilterOption_site
        ...SiteFilterSelected_site
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment SiteFilterOption_site on Site {
  name
  id
}

fragment SiteFilterSelected_site on Site {
  name
}

fragment StoryActionsContainer_story on Story {
  id
  status
}

fragment StoryActionsContainer_viewer on User {
  id
  role
}

fragment StoryRowContainer_story on Story {
  id
  metadata {
    title
    author
    publishedAt
  }
  commentCounts {
    totalPublished
  }
  moderationQueues {
    reported {
      count
      id
    }
    pending {
      count
      id
    }
  }
  viewerCount
  site {
    name
    id
  }
  canModerate
  isClosed
  ...StoryActionsContainer_story
  ...StoryStatusContainer_story
}

fragment StoryRowContainer_viewer on User {
  id
  ...StoryActionsContainer_viewer
}

fragment StoryStatusContainer_story on Story {
  id
  status
}

fragment StoryTableContainer_query_3Tn0NJ on Query {
  viewer {
    ...StoryRowContainer_viewer
    id
  }
  settings {
    multisite
    id
  }
  ...SiteFilterContainer_query
  stories(first: 10, query: $searchFilter) {
    edges {
      node {
        id
        ...StoryRowContainer_story
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "searchFilter"
        } as any)
    ], v1 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v2 = ({
        "kind": "Literal",
        "name": "first",
        "value": 10
    } as any), v3 = [
        (v2 /*: any*/)
    ], v4 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any), v5 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    } as any), v6 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
    } as any), v7 = ({
        "alias": null,
        "args": null,
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any), v8 = [
        (v2 /*: any*/),
        ({
            "kind": "Variable",
            "name": "query",
            "variableName": "searchFilter"
        } as any)
    ], v9 = [
        ({
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "count",
            "storageKey": null
        } as any),
        (v1 /*: any*/)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "StoriesRouteQuery",
            "selections": [
                {
                    "args": [
                        {
                            "kind": "Variable",
                            "name": "searchFilter",
                            "variableName": "searchFilter"
                        }
                    ],
                    "kind": "FragmentSpread",
                    "name": "StoryTableContainer_query"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "StoriesRouteQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "role",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Settings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "multisite",
                            "storageKey": null
                        },
                        (v1 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v3 /*: any*/),
                    "concreteType": "SitesConnection",
                    "kind": "LinkedField",
                    "name": "sites",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "SiteEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Site",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v1 /*: any*/),
                                        (v4 /*: any*/),
                                        (v5 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                (v6 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v7 /*: any*/)
                    ],
                    "storageKey": "sites(first:10)"
                },
                {
                    "alias": null,
                    "args": (v3 /*: any*/),
                    "filters": null,
                    "handle": "connection",
                    "key": "SitesConfig_sites",
                    "kind": "LinkedHandle",
                    "name": "sites"
                },
                {
                    "alias": null,
                    "args": (v8 /*: any*/),
                    "concreteType": "StoriesConnection",
                    "kind": "LinkedField",
                    "name": "stories",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "StoryEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Story",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v1 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "StoryMetadata",
                                            "kind": "LinkedField",
                                            "name": "metadata",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "title",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "author",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "publishedAt",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "CommentCounts",
                                            "kind": "LinkedField",
                                            "name": "commentCounts",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "totalPublished",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ModerationQueues",
                                            "kind": "LinkedField",
                                            "name": "moderationQueues",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "ModerationQueue",
                                                    "kind": "LinkedField",
                                                    "name": "reported",
                                                    "plural": false,
                                                    "selections": (v9 /*: any*/),
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "ModerationQueue",
                                                    "kind": "LinkedField",
                                                    "name": "pending",
                                                    "plural": false,
                                                    "selections": (v9 /*: any*/),
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "viewerCount",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Site",
                                            "kind": "LinkedField",
                                            "name": "site",
                                            "plural": false,
                                            "selections": [
                                                (v4 /*: any*/),
                                                (v1 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "canModerate",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "isClosed",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "status",
                                            "storageKey": null
                                        },
                                        (v5 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                (v6 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v7 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v8 /*: any*/),
                    "filters": [
                        "status",
                        "query",
                        "siteID"
                    ],
                    "handle": "connection",
                    "key": "StoryTable_stories",
                    "kind": "LinkedHandle",
                    "name": "stories"
                }
            ]
        },
        "params": {
            "id": "f22aacccb0d47bdc5469c90ce3baa984",
            "metadata": {},
            "name": "StoriesRouteQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = 'd402bd5fc96864cdd41d3ec701ef8212';
export default node;
