/* tslint:disable */
/* eslint-disable */
/* @relayHash 1e8fd1274fd3a93fc0502663ee83977f */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UserStatusSitesListQueryVariables = {};
export type UserStatusSitesListQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"UserStatusSitesListContainer_query">;
};
export type UserStatusSitesListQuery = {
    readonly response: UserStatusSitesListQueryResponse;
    readonly variables: UserStatusSitesListQueryVariables;
};



/*
query UserStatusSitesListQuery {
  ...UserStatusSitesListContainer_query
}

fragment UserStatusSitesListContainer_query on Query {
  sites(first: 20) {
    edges {
      node {
        id
        name
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "Literal",
            "name": "first",
            "value": 20
        } as any)
    ];
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "UserStatusSitesListQuery",
            "selections": [
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "UserStatusSitesListContainer_query"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "UserStatusSitesListQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v0 /*: any*/),
                    "concreteType": "SitesConnection",
                    "kind": "LinkedField",
                    "name": "sites",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "SiteEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Site",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "id",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "name",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": "sites(first:20)"
                },
                {
                    "alias": null,
                    "args": (v0 /*: any*/),
                    "filters": null,
                    "handle": "connection",
                    "key": "UserStatusSitesListContainer_sites",
                    "kind": "LinkedHandle",
                    "name": "sites"
                }
            ]
        },
        "params": {
            "id": "1e8fd1274fd3a93fc0502663ee83977f",
            "metadata": {},
            "name": "UserStatusSitesListQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = '586988cebc66af2c643738fb70d08061';
export default node;
