/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type FEATURE_FLAG = "ALTERNATE_OLDEST_FIRST_VIEW" | "AVATARS" | "DEFAULT_QA_STORY_MODE" | "DISABLE_LIVE_UPDATES" | "DISABLE_WARN_USER_OF_TOXIC_COMMENT" | "DISCUSSIONS" | "ENABLE_AMP" | "ENABLE_QA" | "ENABLE_RATINGS_AND_REVIEWS" | "EXTERNAL_MEDIA" | "FLATTEN_REPLIES" | "NEW_COMMENT_COUNT" | "READ_MORE_NEW_TAB" | "REDUCED_SECURITY_MODE" | "RTE_SARCASM" | "SECTIONS" | "SITE_MODERATOR" | "VIEWER_COUNT" | "%future added value";
export type ModerateSearchBarContainer_settings = {
    readonly multisite: boolean;
    readonly featureFlags: ReadonlyArray<FEATURE_FLAG>;
    readonly " $refType": "ModerateSearchBarContainer_settings";
};
export type ModerateSearchBarContainer_settings$data = ModerateSearchBarContainer_settings;
export type ModerateSearchBarContainer_settings$key = {
    readonly " $data"?: ModerateSearchBarContainer_settings$data;
    readonly " $fragmentRefs": FragmentRefs<"ModerateSearchBarContainer_settings">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ModerateSearchBarContainer_settings",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "multisite",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "featureFlags",
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = 'a8cbe1389970e46aa0607d903d49bcb7';
export default node;
